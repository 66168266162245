// SpecialtiesSelection.js
import React, { useState, useMemo } from 'react';
import { Box, SimpleGrid, InputGroup, InputLeftElement, Input, VStack } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import SpecialtyCard from './SpecialtyCard';

function SpecialtiesSelection({ specialties, onSelect }) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredSpecialties = useMemo(() => {
        return specialties.filter((specialty) =>
            specialty.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [specialties, searchTerm]);

    return (
        <Box width="100%" maxW="1200px" mx="auto">
            <VStack width="100%">
                <InputGroup width="100%" mb={4}>
                    <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.400" />
                    </InputLeftElement>
                    <Input

                        type="text"
                        placeholder="Caută specialitate"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        borderRadius="md"
                    />
                </InputGroup>
            </VStack>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={6}>
                {filteredSpecialties.map((specialty) => (
                    <SpecialtyCard
                        key={specialty.id}
                        specialty={specialty}
                        onSelect={onSelect}
                    />
                ))}
            </SimpleGrid>
        </Box>
    );
}

export default SpecialtiesSelection;
