// ReservationFlow.js

import React, {Suspense, useEffect, useRef, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Spinner,
    Text,
    useBreakpointValue,
    VStack
} from '@chakra-ui/react';
import {ArrowBackIcon, ArrowForwardIcon, CheckIcon} from '@chakra-ui/icons';
import axios from 'axios';
import io from 'socket.io-client';

import SpecialtiesSelection from './SpecialtiesSelection';
import DoctorsSelection from './DoctorsSelection';

const TimeSlotSelection = React.lazy(() => import('./TimeSlotSelection'));
const Step1 = React.lazy(() => import('./Step1'));
const ConfirmationStep = React.lazy(() => import('./ConfirmationStep'));

const API_URL = process.env.REACT_APP_API_URL;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const socket = io(SOCKET_URL);

function ReservationFlow() {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [formData, setFormData] = useState({
        name: '', surname: '',
        phoneNumber: '', service: '',
        date: '', time: '',
        IDNP: '', noIDNP: false,
        email: '', noEmail: false,
        gender: '', age: '',
    });

    const [specialties, setSpecialties] = useState([]);
    const [doctorProfiles, setDoctorProfiles] = useState([]);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [appointmentDetails, setAppointmentDetails] = useState({});
    const [formValid, setFormValid] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const stepItems = [
        { title: 'Specialitate', description: 'Alege o Specialitate' },
        { title: 'Doctor', description: 'Selectează un Doctor' },
        { title: 'Program', description: 'Timpul Dorit' },
        { title: 'Date', description: 'Datele Personale' },
        { title: 'Final', description: 'Confirmarea Detaliilor' }
    ];

    const stepOrder = [
        '/reservation/selectSpecialty',
        '/reservation/selectDoctor',
        '/reservation/selectTimeSlot',
        '/reservation/fillForm',
        '/reservation/confirmAppointment'
    ];

    const currentStepIndex = stepOrder.indexOf(location.pathname);

    // Keep track of previous path to detect backward/forward navigation
    const prevPathRef = useRef(location.pathname);

    useEffect(() => {
        const fetchSpecialties = async () => {
            try {
                const response = await axios.get(`${API_URL}/specialties`);
                const sortedSpecialties = response.data
                    .filter(s => s.is_active)
                    .sort((a, b) => a.name.localeCompare(b.name));
                setSpecialties(sortedSpecialties);
            } catch (error) {
                console.error('Failed to fetch specialties:', error);
            }
        };
        fetchSpecialties();
    }, []);

    useEffect(() => {
        const path = location.pathname;
        const prevPath = prevPathRef.current;

        const redirect = (to) => navigate(to, { replace: true });

        // If confirmed and tries to go anywhere but confirm page, reset and go to first step (replace to keep history clean)
        if (isConfirmed && path !== '/reservation/confirmAppointment') {
            setIsConfirmed(false);
            setSelectedSpecialty(null);
            setSelectedDoctor(null);
            setSelectedTimeSlot(null);
            setFormData({
                name: '', surname: '',
                phoneNumber: '', service: '',
                date: '', time: '',
                IDNP: '', noIDNP: false,
                email: '', noEmail: false,
                gender: '', age: '',
            });
            redirect('/reservation/selectSpecialty');
            prevPathRef.current = '/reservation/selectSpecialty';
            return;
        }

        // Guard: must have specialty before doctor
        if (path === '/reservation/selectDoctor' && selectedSpecialty === null) {
            redirect('/reservation/selectSpecialty');
        }
        // Guard: must have doctor+specialty before timeSlot
        else if (path === '/reservation/selectTimeSlot' && (!selectedDoctor || !selectedSpecialty)) {
            redirect('/reservation/selectDoctor');
        }
        // Guard: must have timeslot before fillForm
        else if (path === '/reservation/fillForm' && !selectedTimeSlot) {
            redirect('/reservation/selectTimeSlot');
        }
        // Guard: must have form valid and timeslot before confirm
        else if (path === '/reservation/confirmAppointment' && (!formValid || !selectedTimeSlot)) {
            redirect('/reservation/fillForm');
        }

        // Detect backward/forward navigation:
        // If user had a slot held (selectedTimeSlot after continuing), and now goes back to a step before fillForm,
        // we must release the slot.
        if (
            selectedTimeSlot &&
            ((prevPath === '/reservation/fillForm' || prevPath === '/reservation/confirmAppointment')
                && (path === '/reservation/selectTimeSlot' || path === '/reservation/selectDoctor' || path === '/reservation/selectSpecialty'))
        ) {
            // Release slot since we're going backwards past the slot holding step
            socket.emit('release_slot', selectedTimeSlot.id);
            setSelectedTimeSlot(null);
        }

        prevPathRef.current = path;
    }, [
        location.pathname, selectedSpecialty, selectedDoctor,
        selectedTimeSlot, formValid, isConfirmed, navigate
    ]);

    const handleSelectSpecialty = async (specialtyId) => {
        setSelectedSpecialty(specialtyId);
        setSelectedDoctor(null);
        setSelectedTimeSlot(null);
        try {
            const response = await axios.get(`${API_URL}/doctor_profiles/specialty/${specialtyId}`);
            setDoctorProfiles(response.data);
        } catch (error) {
            console.error('Failed to fetch doctor profiles:', error);
            setDoctorProfiles([]);
        }
        // User-driven navigation is OK to not replace
        navigate('/reservation/selectDoctor');
    };

    const handleViewProfile = async (doctorId, specialtyId) => {
        try {
            await axios.get(`${API_URL}/doctor_schedules/available/${doctorId}/${specialtyId}`);
            const doc = doctorProfiles.find(doctor => doctor.doctor_id === doctorId);
            setSelectedDoctor(doc);
            setSelectedTimeSlot(null);
            // User-driven
            navigate('/reservation/selectTimeSlot');
        } catch (error) {
            console.error('Error fetching timeslots:', error);
        }
    };

    const onTimeslotSelected = (ts) => {
        setSelectedTimeSlot(ts);
    };

    const handleTimeSlotContinue = () => {
        if (selectedTimeSlot) {
            // Hold the chosen slot on continue
            socket.emit('hold_slot', selectedTimeSlot.id);
            // User-driven navigation
            navigate('/reservation/fillForm');
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (!formValid) {
            alert('Verificați formularul pentru erori.');
            return;
        }

        let appointmentDate = '';
        const specialty = specialties.find(s => s.id === selectedSpecialty);
        const doctor = selectedDoctor;

        const formatTime = (timeString) => {
            const [hours, minutes] = timeString.split(':');
            return `${hours}:${minutes}`;
        };

        if (selectedTimeSlot && selectedTimeSlot.date) {
            const date = new Date(selectedTimeSlot.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            appointmentDate = utcDate.toISOString().split('T')[0];
        }

        const details = {
            doctorId: doctor.doctor_id,
            specialtyId: specialty.id,
            specialtyName: specialty.name,
            doctorName: doctor.name,
            doctorSurname: doctor.surname,
            appointmentDate: appointmentDate,
            date: appointmentDate,
            startTime: formatTime(selectedTimeSlot.start_time),
            endTime: formatTime(selectedTimeSlot.end_time),
            patientName: formData.name,
            patientSurname: formData.surname,
            patientPhone: formData.phoneNumber,
            patientEmail: formData.noEmail ? null : formData.email,
            IDNP: formData.noIDNP ? null : formData.IDNP,
            gender: formData.gender,
            age: formData.age,
            slotId: selectedTimeSlot.id,
            formData: formData
        };
        setAppointmentDetails(details);
        // User-driven navigation
        navigate('/reservation/confirmAppointment');
    };

    const handleBackClick = () => {
        // If user goes back from fillForm to timeSlot, release held slot
        if (location.pathname === '/reservation/fillForm' && selectedTimeSlot) {
            socket.emit('release_slot', selectedTimeSlot.id);
            setSelectedTimeSlot(null);
        }
        // User-driven backward navigation
        if (isConfirmed || currentStepIndex <= 0) {
            navigate('/reservation/selectSpecialty', { replace: true });
        } else {
            navigate(-1);
        }
    };

    const renderHeading = () => {
        switch (location.pathname) {
            case '/reservation/selectSpecialty':
                return 'Selectează Specialitatea';
            case '/reservation/selectDoctor':
                return 'Selectează Medicul';
            case '/reservation/selectTimeSlot':
                return 'Selectează Data și Ora';
            case '/reservation/fillForm':
                return 'Completează Formularul';
            case '/reservation/confirmAppointment':
                return 'Pasul Final';
            default:
                return '';
        }
    };

    const isStepCompleted = (stepIndex) => {
        if (stepIndex < currentStepIndex) return true;
        const isLastStep = stepIndex === stepItems.length - 1;
        return isLastStep && isConfirmed;

    };

    const renderStepper = () => {
        const stepWidth = isMobile ? '64px' : '160px';
        const iconSize = isMobile ? '24px' : '38px';
        const fontSize = isMobile ? 'xs' : 'sm';

        return (
            <Box
                width="100%"
                px={{ base: 4, md: 8 }}
                py={6}
            >
                <Flex
                    width="100%"
                    justify="space-between"
                    align="center"
                    position="relative"
                    mx="auto"
                    maxW="1200px"
                >
                    <Box
                        position="absolute"
                        top="50%"
                        left={`calc(${iconSize} / 2)`}
                        right={`calc(${iconSize} / 2)`}
                        height="2px"
                        bg="gray.200"
                        transform="translateY(-50%)"
                        zIndex={0}
                    />
                    <Box
                        position="absolute"
                        top="50%"
                        left={`calc(${iconSize} / 2)`}
                        width={
                            isConfirmed
                                ? '100%'
                                : currentStepIndex > 0
                                    ? `calc((100% - ${iconSize}) * ${currentStepIndex / (stepItems.length - 1)})`
                                    : '0%'
                        }
                        height="2px"
                        bg="blue.500"
                        transform="translateY(-50%)"
                        transition="width 0.3s ease-in-out"
                        zIndex={0}
                    />

                    {stepItems.map((step, index) => {
                        const completed = isStepCompleted(index);
                        const active = index === currentStepIndex && !isConfirmed;
                        const circleColor = completed ? 'green.500'
                            : active ? 'blue.500' : 'gray.200';
                        return (
                            <Flex
                                key={index}
                                direction="column"
                                align="center"
                                flex="1"
                                minW={stepWidth}
                                position="relative"
                                zIndex={1}
                            >
                                <Box
                                    width={iconSize}
                                    height={iconSize}
                                    borderRadius="full"
                                    bg={circleColor}
                                    color="white"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    transition="all 0.2s"
                                    position="relative"
                                    {...(active && {
                                        _before: {
                                            content: '""',
                                            position: 'absolute',
                                            top: '-4px',
                                            left: '-4px',
                                            right: '-4px',
                                            bottom: '-4px',
                                            borderRadius: 'full',
                                            border: '2px solid',
                                            borderColor: 'blue.500',
                                        }
                                    })}
                                >
                                    {completed ? (
                                        <CheckIcon boxSize={isMobile ? 3 : 4} />
                                    ) : (
                                        <Text
                                            fontSize={isMobile ? "xs" : "sm"}
                                            fontWeight="bold"
                                        >
                                            {index + 1}
                                        </Text>
                                    )}
                                </Box>

                                {!isMobile && (
                                    <VStack
                                        spacing={0}
                                        mt={2}
                                        opacity={!completed && !active ? 0.5 : 1}
                                        transition="opacity 0.2s"
                                    >
                                        <Text
                                            fontSize={fontSize}
                                            fontWeight="bold"
                                            color={active ? 'blue.500' : 'gray.700'}
                                            textAlign="center"
                                        >
                                            {step.title}
                                        </Text>
                                        <Text
                                            fontSize="xs"
                                            color="gray.500"
                                            textAlign="center"
                                            display={{ base: 'none', lg: 'block' }}
                                        >
                                            {step.description}
                                        </Text>
                                    </VStack>
                                )}
                            </Flex>
                        );
                    })}
                </Flex>
            </Box>
        );
    };

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            margin="0 auto"
            padding="0"
            direction="column"
            width="100%"
            mb='50'
        >
            <Box width="100%" maxW="1200px" bg="white">
                {renderStepper()}
            </Box>
            <Divider />

            <VStack width="100%" spacing={4}>
                <Grid
                    templateColumns="repeat(3, 1fr)"
                    alignItems="center"
                    width="100%"
                    px={4}
                    py={4}
                >
                    <GridItem colSpan={1}>
                        {
                            (currentStepIndex > 0 && !isConfirmed) && (
                                <Button
                                    leftIcon={<ArrowBackIcon />}
                                    size="sm"
                                    variant="outline"
                                    onClick={handleBackClick}
                                    ml={{ base: 0, md: '25%' }}
                                >
                                    Înapoi
                                </Button>
                            )
                        }
                    </GridItem>
                    <GridItem colSpan={1} textAlign="center">
                        <Heading size={{ base: "md", md: "lg" }}>{renderHeading()}</Heading>
                    </GridItem>

                    <GridItem colSpan={1} visibility={currentStepIndex === 0 ? 'hidden' : 'visible'}>
                        <Button size="sm" visibility="hidden">Placeholder</Button>
                    </GridItem>
                </Grid>

            </VStack>

            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path="selectSpecialty" element={
                        <SpecialtiesSelection specialties={specialties} onSelect={handleSelectSpecialty} />
                    } />
                    <Route path="selectDoctor" element={
                        <DoctorsSelection
                            specialties={specialties}
                            selectedSpecialty={selectedSpecialty}
                            doctorProfiles={doctorProfiles}
                            onViewProfile={handleViewProfile}
                        />
                    } />
                    <Route path="selectTimeSlot" element={
                        <TimeSlotSelection
                            doctorId={selectedDoctor?.doctor_id}
                            specialtyId={selectedSpecialty}
                            onTimeslotSelected={onTimeslotSelected}
                            selectedTimeSlot={selectedTimeSlot}
                        />
                    } />
                    <Route path="fillForm" element={
                        <>
                            <Step1
                                formData={formData}
                                setFormData={setFormData}
                                setFormValid={setFormValid}
                            />
                            <Button
                                rightIcon={<ArrowForwardIcon size={24} />}
                                mt='5'
                                size="lg"
                                variant='solid'
                                colorScheme='blue'
                                onClick={handleFormSubmit}
                            >
                                Pasul Final
                            </Button>
                        </>
                    } />
                    <Route path="confirmAppointment" element={
                        <ConfirmationStep
                            appointmentDetails={appointmentDetails}
                            onEdit={() => navigate('/reservation/fillForm')}
                            onConfirm={() => setIsConfirmed(true)}
                        />
                    } />
                </Routes>
            </Suspense>

            {location.pathname === '/reservation/selectTimeSlot' && selectedTimeSlot && !isConfirmed && (
                <Button
                    mt='5'
                    size="lg"
                    variant='solid'
                    colorScheme='blue'
                    onClick={handleTimeSlotContinue}
                >
                    Continuă
                </Button>
            )}
        </Flex>
    );
}

export default ReservationFlow;
