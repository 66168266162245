import {
    Box, Flex, Avatar, HStack, Link, Stack, IconButton, useDisclosure,
    useColorModeValue, Heading, Container
} from '@chakra-ui/react';

import logoImage from '../assets/logo_crdm.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';

const navLinks = [
    { name: 'Despre Noi', path: 'https://crdm.md/pages/scurt_istoric.html' },
    { name: 'Contacte', path: 'https://crdm.md/pages/contacte.html' }
];

export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();



    return (
        <Box px={4} boxShadow="sm" background="blue.400" width="100%"  position="relative">
            <Container maxW="1200px" px={{ base: 2, md: 4 }}>
                <Flex h={16} position="relative">
                    {/* Left section with logo/hamburger */}
                    <Flex
                        position="absolute"
                        left={0}
                        h="100%"
                        alignItems="center"
                        zIndex={2}
                    >
                        <IconButton
                            size="sm"
                            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
                            aria-label="Open Menu"
                            display={{ base: 'flex', md: 'none' }}
                            onClick={isOpen ? onClose : onOpen}
                            variant="ghost"
                            color="white"
                            _hover={{ bg: 'blue.500' }}
                        />
                        <Avatar
                            as={Link}
                            href="https://programare.crdm.md/"
                            size={{ base: 'sm', md: 'md' }}
                            showBorder={true}
                            borderColor="blue.400"
                            rounded="full"
                            src={logoImage}
                            ml={{ base: 2, md: 0 }}
                        />
                    </Flex>

                    {/* Center section with heading */}
                    <Flex
                        justify="center"
                        align="center"
                        w="100%"
                        zIndex={1}
                    >
                        <Heading
                            size={{ base: 'sm', sm: 'md', md: 'lg' }}
                            fontWeight="bold"
                            color="white"
                            textAlign="center"
                            isTruncated
                        >
                            CRDM - Programări Online
                        </Heading>
                    </Flex>

                    {/* Right section with navigation */}
                    <Flex
                        position="absolute"
                        right={0}
                        h="100%"
                        alignItems="center"
                        zIndex={2}
                    >
                        <HStack
                            as="nav"
                            spacing={1}
                            display={{ base: 'none', md: 'flex' }}
                            alignItems="center"
                        >
                            {navLinks.map((link, index) => (
                                <NavLink key={index} {...link} onClose={onClose} />
                            ))}
                        </HStack>
                    </Flex>
                </Flex>


                {isOpen && (
                    <Box pb={4} display={{ base: 'block', md: 'none' }}>
                        <Stack as="nav" spacing={2}>
                            {navLinks.map((link, index) => (
                                <NavLink key={index} {...link} onClose={onClose} />
                            ))}
                        </Stack>
                    </Box>
                )}
            </Container>
        </Box>
    );
}

// NavLink Component
function NavLink({ name, path, onClose }) {
    return (
        <Link
            href={path}
            px={3}
            py={1}
            lineHeight="inherit"
            rounded="md"
            color="white"
            _hover={{
                textDecoration: 'none',
                bg: 'blue.500',
            }}
            onClick={() => onClose()}
        >
            {name}
        </Link>
    );
}