// DoctorsSelection.js
import React from 'react';
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import DoctorCard from './DoctorCard';

function DoctorsSelection({ specialties, selectedSpecialty, doctorProfiles, onViewProfile }) {
    if (!selectedSpecialty) {
        return <Text>Vă rugăm alegeți o specialitate mai întâi.</Text>;
    }

    const specialtyName = specialties.find((s) => s.id === selectedSpecialty)?.name;

    return (
        <Box width="100%" maxW="1200px" mx="auto">
            <Heading fontSize={{ base: 'xl', md: '2xl' }} mb={6} textAlign="center">
                {specialtyName}
            </Heading>
            {doctorProfiles.length === 0 ? (
                <Box p={2}>La moment nu există specialiști pentru această specialitate.</Box>
            ) : (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    {doctorProfiles.map((doctor) => (
                        <DoctorCard
                            key={doctor.doctor_id}
                            doctor={doctor}
                            onViewProfile={onViewProfile}
                            specialtyId={selectedSpecialty}
                            specialties={specialties}
                        />
                    ))}
                </SimpleGrid>
            )}
        </Box>
    );
}

export default DoctorsSelection;
