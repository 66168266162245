import React, { useState } from 'react';
import { Card, CardBody, Heading, Text, Button, Box, Image } from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';

const API_URL = process.env.REACT_APP_API_URL;

const SpecialtyCard = ({ specialty, onSelect }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = (e) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    return (
        <Card
            borderRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{
                transform: 'translateY(-5px)',
                boxShadow: 'xl',
            }}
            onClick={() => onSelect(specialty.id)}
            cursor="pointer"
        >
            <Box position="relative" height="200px">
                <Image
                    src={`${API_URL}/uploads/${specialty.image_url}`}
                    alt={specialty.name}
                    objectFit="cover"
                    w="100%"
                    h="100%"
                />
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    bg="blackAlpha.600"
                    transition="all 0.3s"
                    opacity="0"
                    _groupHover={{ opacity: 1 }}
                />
            </Box>
            <CardBody>
                <Heading size="md" mb={2} textAlign="center">
                    {specialty.name}
                </Heading>
                <Text
                    fontSize="sm"
                    color="gray.600"
                    textAlign="center"
                    noOfLines={isExpanded ? undefined : 3}
                >
                    {specialty.description}
                </Text>
                <Button
                    size="sm"
                    variant="ghost"
                    onClick={toggleExpand}
                    mt={2}
                    width="100%"
                    rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                    {isExpanded ? 'Arată mai puțin' : 'Citeşte mai mult'}
                </Button>
                <Box
                    mt={4}
                    textAlign="center"
                    color="blue.500"
                    fontWeight="bold"
                    fontSize="sm"
                    textTransform="uppercase"
                >
                    Selectați specialistul
                </Box>
            </CardBody>
        </Card>
    );
};

export default SpecialtyCard;
