import React from 'react';
import { Box, Badge, Heading, Image, VStack, Flex, Button, useColorModeValue } from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';

const API_URL = process.env.REACT_APP_API_URL;

const DoctorCard = ({ doctor, onViewProfile, specialtyId, specialties }) => {
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('blue.100', 'blue.500');
    const textColor = useColorModeValue('gray.900', 'gray.200');
    const specialtyName = specialties.find((s) => s.id === specialtyId)?.name;

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            borderColor={borderColor}
            overflow="hidden"
            boxShadow="lg"
            bg={cardBg}
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl' }}
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Flex p={4} direction="column" flex="1">
                <Flex mb={2}>
                    <Box flexShrink={0} mr={4}>
                        <Image
                            boxShadow='base'
                            borderRadius="md"
                            src={doctor.image_url ? `${API_URL}${doctor.image_url}` : 'default-avatar.png'}
                            alt={doctor.name}
                            objectFit="cover"
                            boxSize="100px"
                            borderColor={borderColor}
                            borderWidth="2px"
                        />
                        <Badge colorScheme="blue" mt={2} textAlign="center" width="100%">
                            {specialtyName}
                        </Badge>
                    </Box>
                    <VStack align="start" flex="1" spacing={2}>
                        <Heading size="md">{`${doctor.name} ${doctor.surname}`}</Heading>
                        <Box color={textColor} fontSize="sm">
                            {doctor.description || 'NU există descriere la moment :)'}
                        </Box>
                    </VStack>
                </Flex>
            </Flex>
            <Box
                cursor={'pointer'}
                p={4}
                borderTop="1px"
                borderColor="blue.100"
                mt="auto"
                onClick={() => onViewProfile(doctor.doctor_id, specialtyId)}
            >
                <Button
                    leftIcon={<TimeIcon />}
                    variant='outline'
                    borderColor={borderColor}
                    colorScheme="blue"
                    size="md"
                    onClick={() => onViewProfile(doctor.doctor_id, specialtyId)}
                    width="100%"
                >
                    Vezi Orarul
                </Button>
            </Box>
        </Box>
    );
};

export default DoctorCard;
