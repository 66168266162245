// App.js
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from "./components/navbar";
import ReservationFlow from './components/ReservationFlow';

function App() {
    return (
        <ChakraProvider>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Navigate to="/reservation/selectSpecialty" replace />} />
                    <Route path="/reservation/*" element={<ReservationFlow />} />
                </Routes>
            </Router>
        </ChakraProvider>
    );
}

export default App;
